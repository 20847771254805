// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use './styles/variables' as v;
@use './styles/animations';
@use './styles/directives';
@use './styles/helpers';
@use './styles/mixins';
@use './styles/searchbar';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes(v.$backoffice-theme);

// Include default material typography so we don't have to use any additional classes
// https://material.angular.io/guide/typography#using-typography-styles-in-your-application
@include mat.typography-hierarchy(v.$backoffice-typography);

// The material icons are stored locally to get the latest version open link:
// https://fonts.googleapis.com/icon?family=Material+Icons|Material+Symbols+Outlined
// Inside the response you should find a url like:
// https://fonts.gstatic.com/s/materialicons/v22/2fcrYFNaTjcS6g4U3t-Y5ZjZjT5FdEJ140U2DJYC3mY.woff2
// download the file and store it in the assets/font folder
// do the same for the outlined icons
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url(assets/font/MaterialIcons-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(assets/font/MaterialSymbols-Outlined.woff) format('woff2');
}
.material-icons-base {
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-icons {
  @extend .material-icons-base;
  font-family: 'Material Icons';
}

.material-symbols-outlined {
  @extend .material-icons-base;
  font-family: 'Material Symbols Outlined';
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
    url(assets/font/Roboto-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), url(assets/font/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto'), url(assets/font/Roboto-Medium.ttf) format('truetype');
}

html,
body {
  height: 100%;
  background-color: #fafafa;
}
body {
  margin: 0;
}

mat-card {
  padding: 16px;
}

/* You can add global styles to this file, and also import other style files */
