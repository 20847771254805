@use '@angular/material' as mat;
@use './variables' as v;

.InspectionKindRow--VENTILATION_SYSTEMS:hover .InspectionKind--VENTILATION_SYSTEMS mat-icon {
    animation: Rotate 10s linear infinite;
}

.InspectionKind--VENTILATION_SYSTEMS mat-icon {
    color: mat.get-color-from-palette(v.$backoffice-grey);
}

.RemainingDays {
    font-weight: 500 !important;
    text-align: center;
    padding: 0.2em !important;
}

.RemainingDays--Good {
    color: mat.get-color-from-palette(v.$backoffice-success, darker-contrast) !important;
    background-color: mat.get-color-from-palette(v.$backoffice-success) !important;
}

.RemainingDays--Medium {
    color: mat.get-color-from-palette(v.$backoffice-medium, darker-contrast) !important;
    background-color: mat.get-color-from-palette(v.$backoffice-medium) !important;
}

.RemainingDays--Bad {
    color: mat.get-color-from-palette(v.$backoffice-warn, darker-contrast) !important;
    background-color: mat.get-color-from-palette(v.$backoffice-warn) !important;
}

.RemainingDays--NA {
    color: mat.get-color-from-palette(v.$backoffice-grey, darker-contrast) !important;
    background-color: mat.get-color-from-palette(v.$backoffice-grey) !important;
}
