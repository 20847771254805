.searchbar-list {
  --mdc-typography-body1-font-size: 14px;
}
backoffice-search-bar,
backoffice-aggregate-search-bar {
  --mdc-typography-body1-font-size: 14px;
  --mat-mdc-form-field-floating-label-scale: 1.2;

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }

  .mat-mdc-form-field-infix {
    width: 80px;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 30px;
    height: 30px;
    padding: 3px;
    bottom: 5px;
    right: 5px;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 22px;
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
    .mat-mdc-form-field-infix {
    padding-top: 16px;
    padding-bottom: 4px;
  }

  .mat-mdc-text-field-wrapper {
    height: 44px;
  }
}
